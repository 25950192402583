<template>
  <div>
    <sm-editable-item
      v-model="form"
      class="telemarketing-item"
      :controllerName="controllerName"
      :isLoading="isLoadingPage"
      :breadcrumbs="breadcrumbs"
      :pageHeader="pageHeader"
      :fields="fields"
      :dependentLinks="dependentLinks"
    >
      <template #organizationPhone="{ field }">
        <p @click="webCallSession" class="editable-form__description--linked">
          {{ field.label }}
        </p>
        <span class="editable-form__linked-text">
          <a
            href="#"
            title="Набрать номер по SIP"
            @click.prevent="webCallSession(form.organizationPhone)"
          >
            {{
              hidePersonalData
                ? 'Совершить вызов'
                : formatPhoneNumber(form.organizationPhone)
            }}
          </a>
          <a
            href="#"
            title="Отправить СМС оповещение на номер телефона"
            @click.prevent="onOpenSmsModal"
          >
            Отправить СМС
          </a>
        </span>
      </template>

      <template #contactPhoneMobile="{ field }">
        <p class="editable-form__description--linked">
          {{ field.label }}
        </p>
        <span class="editable-form__linked-text">
          <template v-if="contactPhoneMobileEdit">
            <sm-input v-model="contactPhoneMobileText" type="text" />
            <div class="telemarketing-form__buttons">
              <button
                class="telemarketing-form__button telemarketing-form__button--confirm"
                @click="onConfirmContactPhoneMobile"
              >
                <sm-icon name="check" />
              </button>
              <button
                class="telemarketing-form__button"
                @click="onCancelContactPhoneMobile"
              >
                <sm-icon outline name="cancel-circle" />
              </button>
            </div>
          </template>
          <template v-else>
            <template v-if="form[field.key]">
              <a
                href="#"
                title="Набрать номер по SIP"
                @click.prevent="webCallSession(form[field.key])"
              >
                {{ formatPhoneNumber(form[field.key]) }}
              </a>
              <button
                class="telemarketing-form__button"
                @click="onEditContactPhoneMobile"
              >
                <sm-icon name="pencil" />
              </button>
            </template>
            <template v-else>
              <sm-input v-model="contactPhoneMobileText" type="text" />
              <button
                class="telemarketing-form__button telemarketing-form__button--confirm"
                @click="onConfirmContactPhoneMobile"
              >
                <sm-icon name="check" />
              </button>
            </template>
          </template>
        </span>
      </template>

      <template #contactPhoneWork="{ field }">
        <p class="editable-form__description--linked">
          {{ field.label }}
        </p>
        <span class="editable-form__linked-text">
          <template v-if="contactPhoneWorkEdit">
            <sm-input v-model="contactPhoneWorkText" type="text" />
            <div class="telemarketing-form__buttons">
              <button
                class="telemarketing-form__button telemarketing-form__button--confirm"
                @click="onConfirmContactPhoneWork"
              >
                <sm-icon name="check" />
              </button>
              <button
                class="telemarketing-form__button"
                @click="onCancelContactPhoneWork"
              >
                <sm-icon outline name="cancel-circle" />
              </button>
            </div>
          </template>
          <template v-else>
            <template v-if="form[field.key]">
              <a
                href="#"
                title="Набрать номер по SIP"
                @click.prevent="webCallSession(form[field.key])"
              >
                {{ formatPhoneNumber(form[field.key]) }}
              </a>
              <button
                class="telemarketing-form__button"
                @click="onEditContactPhoneWork"
              >
                <sm-icon name="pencil" />
              </button>
            </template>
            <template v-else>
              <sm-input v-model="contactPhoneWorkText" type="text" />
              <button
                class="telemarketing-form__button telemarketing-form__button--confirm"
                @click="onConfirmContactPhoneWork"
              >
                <sm-icon name="check" />
              </button>
            </template>
          </template>
        </span>
      </template>

      <template #scriptText="{ field }">
        <p class="editable-form__description">{{ field.label }}</p>
        <sm-textarea
          v-model="currentScriptText"
          rows="10"
          resize="vertical"
          :disabled="IsСurrentScriptTextDisabled"
        />
      </template>

      <template #contacts>
        <div
          v-for="(item, index) in form.contacts"
          :key="`contact-${index}`"
          class="telemarketing-form__row"
        >
          <div class="telemarketing-form__wrapper">
            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">
                ФИО контактного лица
              </p>
              <sm-input
                v-model="form.contacts[index].fio"
                type="text"
                :disabled="!!item.id"
              />
            </div>

            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">
                Email контактного лица
              </p>
              <sm-input
                v-model="form.contacts[index].mail"
                type="text"
                :disabled="!!item.id"
              />
            </div>

            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">
                Телефон контактного лица
              </p>
              <div class="editable-form__linked-text">
                <template v-if="form.contacts[index].isEdit">
                  <sm-input v-model="form.contacts[index].text" type="text" />
                  <div class="telemarketing-form__buttons">
                    <button
                      class="telemarketing-form__button telemarketing-form__button--confirm"
                      @click="onConfirmContactsFormPhone(index)"
                    >
                      <sm-icon name="check" />
                    </button>
                    <button
                      class="telemarketing-form__button"
                      @click="onCancelContactsFormPhone(index)"
                    >
                      <sm-icon outline name="cancel-circle" />
                    </button>
                  </div>
                </template>
                <template v-else>
                  <template v-if="form.contacts[index].phone">
                    <a
                      href="#"
                      title="Набрать номер по SIP"
                      @click.prevent="
                        webCallSession(form.contacts[index].phone)
                      "
                    >
                      {{ formatPhoneNumber(form.contacts[index].phone) }}
                    </a>
                    <button
                      v-if="!item.id"
                      class="telemarketing-form__button"
                      @click="onEditContactsFormPhone(index)"
                    >
                      <sm-icon name="pencil" />
                    </button>
                  </template>
                  <template v-else>
                    <sm-input
                      v-model="form.contacts[index].text"
                      type="text"
                      :disabled="!!item.id"
                    />
                    <button
                      v-if="!item.id"
                      class="telemarketing-form__button telemarketing-form__button--confirm"
                      @click="onConfirmContactsFormPhone(index)"
                    >
                      <sm-icon name="check" />
                    </button>
                  </template>
                </template>
              </div>
            </div>

            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">
                Должность контактного лица
              </p>
              <sm-input
                v-model="form.contacts[index].position"
                type="text"
                :disabled="!!item.id"
              />
            </div>
          </div>

          <button
            v-show="form.contacts.length"
            class="telemarketing-form__button"
            @click="openDeleteContactModal(index)"
          >
            <sm-icon name="trash" />
          </button>
        </div>

        <button
          class="telemarketing-form__button telemarketing-form__button--add"
          @click="onAddContact"
        >
          <sm-icon name="plus" />
          Добавить контакт
        </button>
      </template>

      <template #checkList>
        <div
          v-for="(item, index) in form.checkList"
          :key="item.id"
          class="telemarketing-form__row"
        >
          <div class="telemarketing-form__wrapper">
            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">Вопрос чек-листа</p>
              <sm-input
                v-model="form.checkList[index].question"
                type="text"
                :disabled="true"
              />
            </div>

            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">Ответ: позитив</p>
              <sm-input
                v-model="form.checkList[index].answerPositive"
                type="text"
              />
            </div>

            <div class="telemarketing-form__column">
              <p class="telemarketing-form__description">Ответ: негатив</p>
              <sm-input
                v-model="form.checkList[index].answerNegative"
                type="text"
              />
            </div>

            <div
              class="telemarketing-form__column telemarketing-form__column_textarea"
            >
              <sm-textarea
                v-model="form.checkList[index].comment"
                label="Комментарий"
                :rows="5"
              />
            </div>
          </div>
        </div>
      </template>

      <template #tab-1>
        <div class="editable-item__tab-content">
          <sm-datatable
            :caption="fields[1].table.caption"
            :headers="fields[1].table.headers"
            :items="fields[1].table.items"
          >
            <template #top-panel>
              <button
                v-if="notCreatedTelemarketingScriptQuestions.length"
                class="editable-list__top-panel-button editable-list__top-panel-button--create"
                @click="openModal(fields[1].table.key)"
              >
                <sm-icon name="plus" />
                Добавить
              </button>
            </template>

            <template #colgroup>
              <col
                width="auto"
                :span="
                  fields[1].table.showEditButton ||
                  fields[1].table.showDeleteButton
                    ? fields[1].table.headers.length - 1
                    : fields[1].table.headers.length
                "
              />
              <col
                v-if="
                  fields[1].table.showEditButton ||
                  fields[1].table.showDeleteButton
                "
                width="80px"
              />
            </template>
            <template v-slot:actions="{ index }">
              <div class="editable-list__datatable-buttons">
                <sm-tooltip
                  position="bottom"
                  text="Удалить"
                  class="editable-list__datatable-tooltip"
                >
                  <button
                    colorType="danger"
                    class="editable-list__datatable-button"
                    @click="openDeleteModal(fields[1].table.key, index)"
                  >
                    <sm-icon
                      name="trash"
                      class="editable-list__datatable-button-icon"
                    />
                  </button>
                </sm-tooltip>
                <sm-tooltip
                  position="bottom"
                  text="Изменить"
                  class="editable-list__datatable-tooltip"
                >
                  <button
                    colorType="success"
                    class="editable-list__datatable-button"
                    @click="openModal(fields[1].table.key, index)"
                  >
                    <sm-icon
                      name="pencil-alt"
                      class="editable-list__datatable-button-icon"
                    />
                  </button>
                </sm-tooltip>
              </div>
            </template>
          </sm-datatable>
          <sm-form-modal
            class="telemarketing-item-modal"
            v-model="answersModalForm"
            :key="modalKey"
            :fields="fields[1].table.modalFields"
            :show="showAnswersModalForm"
            :modal-title="fields[1].table.caption"
            @select="onSelectFromModal"
            @close="onCancelSaveModalForm"
            @save="onSaveModalForm(fields[1].table.key)"
            @cancel="onCancelSaveModalForm"
          />
        </div>
      </template>

      <template #footer>
        <sm-button
          class="editable-item__form-button"
          :disabled="isEqualForm"
          :isLoading="isLoadingUpdateButton"
          @click="onUpdate"
          >Сохранить</sm-button
        >
        <sm-button
          class="editable-item__form-button"
          :disabled="isEqualForm"
          :isLoading="isLoadingSaveButton"
          @click="onSaveForm()"
          >Сохранить и закрыть</sm-button
        >
        <sm-button
          class="editable-item__form-button"
          outline
          neutrall
          @click="onCancel('TelemarketingTasksForCurrentUser')"
          >Отменить</sm-button
        >
        <sm-button
          class="editable-item__form-button"
          :isLoading="isLoadingSendingToAmo"
          @click="sendToAmo"
          >Отправить в AMO</sm-button
        >

        <sm-button
          class="editable-item__form-button telemarketing-item__button"
          :isLoading="isLoadingSendingEmail"
          @click="onSendEmail"
          >Отправить коммерческое предложение</sm-button
        >

        <sm-button class="editable-item__form-button" @click="onCreateRelation"
          >Создать напоминание</sm-button
        >

        <!-- <wd-zadarma-phone
        v-if="sipKey"
        class="widget__webphone"
        :sipKey="sipKey"
        :sipNumber="sipNumber"
      /> -->
      </template>
    </sm-editable-item>

    <sm-form-modal
      v-model="smsForm"
      :fields="smsModalFields"
      :show="showSmsModalForm"
      :modal-title="modalSendSmsTitle"
      :isLoadingSaveButton="isLoadingSendingSms"
      @close="onCancelSendSms"
      @save="onSendSms"
      @cancel="onCancelSendSms"
    >
      <template v-slot:text="{ field }">
        <div class="telemarketing-form__sms-wrapper">
          <sm-textarea v-model="smsForm.text" :label="field.label" :rows="5" />
          <p
            v-show="smsForm.text.length > 67"
            class="telemarketing-form__sms-alert"
          >
            Текст сообщения превышает 67 символов. Будет отправлено несколько
            сообщений.
          </p>
        </div>
      </template>
    </sm-form-modal>
  </div>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmTextarea from '@/components/common/forms/SmTextarea.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
// import WdZadarmaPhone from '@/components/widgets/WdZadarmaPhone.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmFormModal from '@/components/common/modals/SmFormModal.vue';
import SmTooltip from '@/components/common/SmTooltip.vue';
import SmDatatable from '@/components/common/SmDatatable.vue';

import formatPhoneNumber from '@/utils/formatPhoneNumber.js';

export default {
  name: 'TelemarketingTaskForCurrentUserEdit',

  components: {
    SmEditableItem,
    SmTextarea,
    SmInput,
    SmButton,
    // WdZadarmaPhone,
    SmIcon,
    SmFormModal,
    SmTooltip,
    SmDatatable,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingTasksForCurrentUser',
      pageHeader:
        'Редактирование задания по телемаркетингу для текущего пользователя',
      form: {},
      modalKey: 0,
      isLoadingSendingToAmo: false,
      isLoadingSendingEmail: false,
      isLoadingUpdateButton: false,
      isLoadingSendingSms: false,
      // sipKey: '',
      // sipNumber: '',
      initialContactForm: {
        fio: null,
        phone: null,
        mail: null,
        position: null,
        isEdit: false,
      },
      contactPhoneMobileEdit: false,
      contactPhoneMobileText: null,
      contactPhoneWorkEdit: false,
      contactPhoneWorkText: null,
      showSmsModalForm: false,
      modalSendSmsTitle: 'Отправить смс',
      smsForm: {
        phone: null,
        text: 'Установите мобильное приложение ГранельЖКХ https://clck.ru/34ZLef',
        sendShortLinks: false,
      },
      smsModalFields: [
        {
          type: 'textarea',
          key: 'text',
          label: 'Текст сообщения:',
        },
        {
          type: 'checkbox',
          key: 'sendShortLinks',
          label: 'Сокращать ссылки',
        },
      ],
      answersModalForm: {
        questionId: null,
        answerId: null,
        comment: null,
        chief: null
      },
      showAnswersModalForm: false,
      modalAnswersTitle: 'Ответы на вопросы скрипта',
      answersModalRenderKey: 0,
      editItemIndex: null,
      telemarketingScriptQuestions: [],
      telemarketingScriptQuestionsAnswers: [],
      currentTelemarketingScriptQuestions: [],
      telemarketingAnswersList: [],
      formAnswersTable: [],
      isAnswersModalFieldMiltiselect: false,
      modalTelemarketingAnswers: [],
      currentScriptText: null,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      employeeAccounts: (state) => state.common.employeeAccounts,
      callResults: (state) => state.common.callResults,
      telemarketingTasksRejectionReasons: (state) =>
        state.common.telemarketingTasksRejectionReasons,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
      telemarketingScriptsForProduct: (state) =>
        state.common.telemarketingScriptsForProduct,
      telemarketingAnswers: (state) => state.common.telemarketingAnswers,
    }),

    hidePersonalData() {
      const hidePersonalData = localStorage.getItem('hidePersonalData');
      return hidePersonalData === 'true' ? true : false;
    },

    isEqualForm() {
      const processedData = {
        ...this.item.data,
        contactPhoneWork: this.item.data.contactPhoneWork
          ? this.item.data.contactPhoneWork
          : this.contactPhoneWorkText,
        contactPhoneMobile: this.item.data.contactPhoneMobile
          ? this.item.data.contactPhoneMobile
          : this.contactPhoneMobileText,
        contacts: this.item.data.contacts.length
          ? this.item.data.contacts.map((item) => ({
              ...item,
              isEdit: false,
              text: null,
            }))
          : [],
      };

      return this.lodash.isEqual(this.form, processedData);
    },

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Задания по телемаркетингу для текущего пользователя',
          route: { name: 'TelemarketingTasksForCurrentUser' },
        },
        {
          text: `Редактирование задания по телемаркетингу для текущего пользователя №${this.form.id}`,
        },
      ];
    },

    phonesValuesFilter() {
      const organizationPhone = this.form.organizationPhone?.startsWith('8')
        ? `7${this.form.organizationPhone?.substring(1)}`
        : this.form.organizationPhone?.replace(/[-, + )(]/g, '');
      const contactPhoneWork = this.form.contactPhoneWork?.startsWith('8')
        ? `7${this.form.contactPhoneWork.substring(1)}`
        : this.form.contactPhoneWork?.replace(/[-, + )(]/g, '');
      const contactPhoneMobile = this.form.contactPhoneMobile?.startsWith('8')
        ? `7${this.form.contactPhoneMobile?.substring(1)}`
        : this.form.contactPhoneMobile?.replace(/[-, + )(]/g, '');

      const arrayPhones = [
        organizationPhone,
        contactPhoneWork,
        contactPhoneMobile,
      ];

      const contactsPhones = this.form?.contacts?.filter((item) => item.phone);

      const processedContactsPhones = contactsPhones?.map((item) => {
        if (!item.phone) return;
        return item.phone?.startsWith('8')
          ? `7${item.phone?.substring(1)}`
          : item.phone?.replace(/[-, + )(]/g, '');
      });

      if (processedContactsPhones && processedContactsPhones.length) {
        arrayPhones.push(processedContactsPhones);
      }

      return arrayPhones.filter(Boolean).join(',');
    },

    dependentLinks() {
      return [
        {
          text: 'Звонки по телемаркетингу',
          route: {
            name: 'SipCallsForTelemarketingTaskCurrentUser',
            params: {
              id: this.form.id,
            },
            query: {
              filter: JSON.stringify([
                {
                  column: 'phone',
                  operation: 'Contain',
                  value: this.phonesValuesFilter,
                },
              ]),
            },
          },
        },

        {
          text: 'Договоры',
          route: {
            name: 'OneCContractsForTelemarketingTaskCurrentUser',
            params: {
              id: this.form.id,
              inn: this.form.organizationInn,
            },
          },
        },

        {
          text: 'Контакты',
          route: {
            name: 'OneCContactsForTelemarketingTaskCurrentUser',
            params: {
              id: this.form.id,
              inn: this.form.organizationInn,
            },
          },
        },
      ];
    },

    scriptText() {
      const { data } = this.telemarketingScriptsForProduct;
      const { scriptId } = this.form;
      const currentScript = data.find((item) => item.id === scriptId);
      if (!currentScript) {
        return null;
      }
      return currentScript.scriptText;
    },

    formCommentsTable() {
      if (
        this.form.questionsComments &&
        this.form.questionsComments.length &&
        this.telemarketingScriptQuestions.length
      ) {
        return this.form.questionsComments.map((item) => {
          let currentQuestion = this.telemarketingScriptQuestions.find(
            (question) => question.value === item.questionId
          );

          return {
            ...item,
            question: currentQuestion?.name,
          };
        });
      }
      return [];
    },

    notCreatedTelemarketingScriptQuestions() {
      const telemarketingScriptQuestions = this.telemarketingScriptQuestions;
      const { answers } = this.form;

      const questions = telemarketingScriptQuestions.filter(({ value }) => {
        const createdAnswer = answers.find(
          ({ questionId }) => questionId === value
        );

        if (createdAnswer) return false;

        return true;
      });

      return questions;
    },

    fields() {
      const { scriptId } = this.form;

      return [
        {
          tab: 'Основные',
          groups: [
            {
              form: [
                {
                  type: 'select',
                  key: 'accountId',
                  label: 'Аккаунт',
                  list: this.employeeAccounts.data,
                },
                {
                  type: 'text',
                  key: 'created',
                  label: 'Дата создания',
                  disabled: true,
                },
                {
                  type: 'text',
                  key: 'completionDate',
                  label: 'Дата выполнения задания',
                  disabled: true,
                },
              ],
            },
            {
              groupTitle: 'Задание',
              form: [
                {
                  type: 'select',
                  key: 'productId',
                  label: 'Продукт телемаркетинга',
                  list: this.telemarketingProducts.data,
                },
                {
                  type: 'select',
                  key: 'scriptId',
                  label: 'Скрипт телемаркетинга',
                  list: this.telemarketingScriptsForProduct.data?.map(
                    (item) => ({
                      ...item,
                      name: item.script,
                    })
                  ),
                },
                {
                  type: 'textarea',
                  key: 'scriptText',
                  label: 'Текст скрипта',
                  disabled: !scriptId,
                },
              ],
            },
            {
              groupTitle: 'Организация',
              form: [
                {
                  type: 'textarea',
                  key: 'organizationName',
                  label: 'Название организации',
                  disabled: true,
                  attributes: {
                    rows: 3,
                  },
                },
                {
                  type: 'text',
                  key: 'organizationPhone',
                  label: 'Телефон организации',
                  disabled: true,
                },
                {
                  type: 'text',
                  key: 'organizationMail',
                  label: 'Email организации',
                  disabled: true,
                },
                {
                  type: 'text',
                  key: 'organizationUrl',
                  label: 'Веб-сайт организации',
                  disabled: true,
                },
                {
                  type: 'text',
                  key: 'organizationInn',
                  label: 'ИНН организации',
                  disabled: true,
                },
                {
                  type: 'text',
                  key: 'chief',
                  label: 'ФИО директора',
                },
              ],
            },
            {
              groupTitle: 'Результат обзвона',
              form: [
                {
                  type: 'select',
                  key: 'callResult',
                  label: 'Результат обзвона',
                  list: this.callResults.data,
                },
                { type: 'text', key: 'tags', label: 'Теги' },
                {
                  type: 'select',
                  key: 'rejectionReasonId',
                  label: 'Причина отказа',
                  list: this.telemarketingTasksRejectionReasons.data,
                },
                { type: 'textarea', key: 'comment', label: 'Комментарий' },
              ],
            },
            {
              groupTitle: 'Контактные лица',
              form: [
                {
                  type: 'textarea',
                  key: 'contactFio',
                  label: 'ФИО контактного лица',
                  attributes: {
                    rows: 3,
                  },
                },
                {
                  type: 'text',
                  key: 'contactMail',
                  label: 'Email контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactPhoneMobile',
                  label: 'Мобильный телефон контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactPosition',
                  label: 'Должность контактного лица',
                },
                {
                  type: 'text',
                  key: 'contactPhoneWork',
                  label: 'Рабочий телефон контактного лица',
                },
                {
                  type: 'textarea',
                  key: 'contacts',
                  label: 'Контакты',
                },
              ],
            },
          ],
        },
        {
          tab: 'Ответы на вопросы скрипта',
          table: {
            caption: 'Ответы на вопросы скрипта',
            headers: [
              {
                text: 'Вопрос',
                alias: 'question',
              },
              {
                text: 'Комментарий',
                alias: 'comment',
              },
              {
                text: 'Ответ',
                alias: 'answer',
              },
              { alias: 'actions' },
            ],
            items: this.form.answers,
            key: 'answers',
            modalFields: [
              {
                type: 'text',
                key: 'chief',
                label: 'ФИО директора',
                disabled: true,
              },
              {
                type: 'select',
                key: 'questionId',
                label: 'Вопрос',
                list: this.currentTelemarketingScriptQuestions,
              },
              {
                type: 'select',
                key: 'answerId',
                label: 'Ответ',
                list: this.modalTelemarketingAnswers,
                attributes: {
                  multiselect: this.isAnswersModalFieldMiltiselect,
                },
              },
              {
                type: 'textarea',
                key: 'comment',
                label: 'Комментарий',
              },
            ],
            showEditButton: true,
          },
        },
        {
          tab: 'Комментарии к вопросам',
          table: {
            caption: 'Комментарии к вопросам',
            headers: [
              {
                text: 'Вопрос',
                alias: 'question',
              },
              {
                text: 'Комментарий',
                alias: 'comment',
              },
              { alias: 'actions' },
            ],
            items: this.formCommentsTable,
            key: 'questionsComments',
            modalFields: [
              {
                type: 'select',
                key: 'questionId',
                label: 'Вопрос',
                list: this.telemarketingScriptQuestions,
              },
              {
                type: 'textarea',
                key: 'comment',
                label: 'Комментарий',
              },
            ],
            showEditButton: true,
            showCreateButton: true,
            showDeleteButton: true,
          },
        },
      ];

      // if (this.showCheckList) {
      //   groups.push({
      //     groupTitle: 'Чек-лист',
      //     form: [
      //       {
      //         type: 'textarea',
      //         key: 'checkList',
      //         label: 'Чек-лист',
      //       },
      //     ],
      //   });
      // }

      // return [
      //   {
      //     groups,
      //   },
      // ];
    },

    showCheckList() {
      return this.$ability.checkPermissions(
        'Table',
        'TelemarketingTasks',
        'CheckList'
      );
    },

    IsСurrentScriptTextDisabled() {
      return !this.form.productId || !this.form.scriptId
    }
  },

  watch: {
    'form.productId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'TelemarketingScriptsForProduct',
            params: { productid: this.form.productId },
          });
        }
      },
    },

    'answersModalForm.questionId': {
      handler(newVal) {
        if (newVal) {
          this.getCommonList({
            name: 'TelemarketingAnswers',
            params: { questionId: this.answersModalForm.questionId },
          }).then(() => {
            const currentsQuestion = this.telemarketingScriptQuestions.find(
              (item) => item.value === this.answersModalForm.questionId
            );

            this.isAnswersModalFieldMiltiselect =
              currentsQuestion.multipleAnswers;

            this.modalTelemarketingAnswers =
              this.telemarketingAnswers.data || [];
          });
        }
      },
      immediate: true,
      deep: true,
    },

    'answersModalForm.answerId': {
      handler(value) {
        if (!value) {
          this.$set(this.answersModalForm, 'answer', '');
          return;
        }

        if (Array.isArray(value)) {
          const findedAnswers = [];

          value.forEach((answerId) => {
            const findedAnswer =
              this.telemarketingAnswers.data.find(
                (item) => item.value === answerId
              )?.name || '';

            if (findedAnswer) findedAnswers.push(findedAnswer);
          });

          this.$set(this.answersModalForm, 'answer', findedAnswers.join('; '));

          return;
        }

        const findedAnswer =
          this.telemarketingAnswers.data.find((item) => item.value === value)
            ?.name || '';

        this.$set(this.answersModalForm, 'answer', findedAnswer);
      },
      immediate: true,
      deep: true,
    },

    'form.scriptId': {
      handler(newVal) {
        if (newVal) {
          this.getTelemarketingScriptQuestions(newVal)
            .then((res) => {
              this.telemarketingScriptQuestions = res.map(
                ({ question, questionId, answers, multipleAnswers }) => {
                  return {
                    name: question,
                    value: questionId,
                    answers,
                    multipleAnswers,
                  };
                }
              );
            })
            .then(() => {
              this.$set(this.form, 'answers', this.getCurrentAnswersTable());
            });
        }
      },
    },

    scriptText(value) {
      this.currentScriptText = value;
    },


    'form.answers': {
      handler(newVal) {
        if (newVal && newVal.length) {
          newVal.forEach((item) => {
            this.getCommonList({
              name: 'TelemarketingAnswers',
              params: { questionId: item.questionId },
            }).then((res) => {
              if (
                !res.some((item) =>
                  this.telemarketingAnswersList.includes(item)
                )
              ) {
                this.telemarketingAnswersList.push(...res);
              }
            });
          });
        }
      },
      immediate: true,
    },

    telemarketingAnswersList: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.formAnswersTable = this.createFormAnswersTable();
        }
      },
      immediate: true,
    },
  },

  created() {
    this.generateSipKey().then(({ key, sip }) => {
      this.sipKey = key;
      this.sipNumber = sip;
    });

    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });
    const callResults = this.getCommonList({ name: 'CallResults' });
    const telemarketingTasksRejectionReasons = this.getCommonList({
      name: 'TelemarketingTasksRejectionReasons',
    });
    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });
    const item = this.getItem({
      name: this.controllerName,
      id: this.id,
    })
      .then(() => {
        const item = this.lodash.cloneDeep(this.item.data);

        const { contacts, answers } = item;

        answers.forEach((item) => {
          this.getCommonList({
            name: 'TelemarketingAnswers',
            params: { questionId: item.questionId },
          }).then((res) => {
            this.telemarketingAnswersList.push(...res);
          });
        });

        this.form = {
          ...item,
          contacts: contacts.map((contact) => ({
            ...contact,
            isEdit: false,
            text: null,
          })),
        };
      })
      .then(() => {
        this.contactPhoneWorkText = this.form.contactPhoneWork;
        this.contactPhoneMobileText = this.form.contactPhoneMobile;
      });

    this.isLoadingPage = true;
    Promise.all([
      employeeAccounts,
      callResults,
      telemarketingTasksRejectionReasons,
      telemarketingProducts,
      item,
    ])
      .then(() => {
        this.isLoadingPage = false;
      })
      .finally(() => {
        this.isLoadingPage = false;
      });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      sendEmail: 'telemarketing/sendEmail',
      sendSms: 'telemarketing/sendSms',
      getCommonList: 'common/getCommonList',
      addCompanyToAmo: 'amoCrm/addCompanyToAmo',
      generateSipKey: 'sip/generateSipKey',
      getTelemarketingScriptQuestions:
        'telemarketing/getTelemarketingScriptQuestions',
    }),

    onConfirmContactPhoneMobile() {
      this.form.contactPhoneMobile = this.contactPhoneMobileText;
      this.contactPhoneMobileEdit = false;
    },

    onEditContactPhoneMobile() {
      this.contactPhoneMobileEdit = true;
      this.contactPhoneMobileText = this.form.contactPhoneMobile;
    },

    onCancelContactPhoneMobile() {
      this.contactPhoneMobileEdit = false;
    },

    onConfirmContactPhoneWork() {
      this.form.contactPhoneWork = this.contactPhoneWorkText;
      this.contactPhoneWorkEdit = false;
    },

    onEditContactPhoneWork() {
      this.contactPhoneWorkEdit = true;
      this.contactPhoneWorkText = this.form.contactPhoneWork;
    },

    onCancelContactPhoneWork() {
      this.contactPhoneWorkEdit = false;
    },

    onConfirmContactsFormPhone(index) {
      this.form.contacts[index].phone = this.form.contacts[index].text;
      this.form.contacts[index].isEdit = false;
    },

    onEditContactsFormPhone(index) {
      this.form.contacts[index].isEdit = true;
      this.form.contacts[index].text = this.form.contacts[index].phone;
    },

    onCancelContactsFormPhone(index) {
      this.form.contacts[index].isEdit = false;
    },

    sendToAmo() {
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      });

      const {
        accountId,
        organizationName,
        organizationPhone,
        organizationMail,
        organizationUrl,
        organizationInn,
        organizationAddress,
        comment,
        contactFio,
        contactPhoneMobile,
        contactMail,
        contactPosition,
        tags,
        productId,
      } = this.form;

      const foundProduct = this.telemarketingProducts.data.find(
        (product) => product.id === productId
      );
      const offeredProduct = foundProduct ? foundProduct.name : '';

      const FormData = require('form-data');
      var formAmo = new FormData();

      formAmo.append(
        'name_tm',
        this.employeeAccounts.data.find((item) => item.id === accountId)?.name
      );
      formAmo.append('company_name', organizationName);
      formAmo.append('work_phone', organizationPhone);
      formAmo.append('work_email', organizationMail);
      formAmo.append('web_site', organizationUrl);
      formAmo.append('inn', organizationInn);
      formAmo.append('kpp', '');
      formAmo.append('ogrn', '');
      formAmo.append('address', organizationAddress);
      formAmo.append(
        'notes',
        `https://aero.sm-center.ru/#/telemarketing/sip-calls-for-telemarketing-task/${this.form.id}?filter=%5B%7B%22column%22%3A%22PhoneList%22,%22operation%22%3A%22Equal%22,%22value%22%3A%22${this.phonesValuesFilter}%22%7D%5D ${comment}`
      );
      formAmo.append('contact_name', contactFio);
      formAmo.append('contact_phone', contactPhoneMobile);
      formAmo.append('contact_email', contactMail);
      formAmo.append('contact_position', contactPosition);
      formAmo.append('tags', tags);
      formAmo.append('offered_product', offeredProduct);

      this.isLoadingSendingToAmo = true;
      this.addCompanyToAmo(formAmo)
        .then(() => {
          this.$notify({
            header: 'задание отправлено в АМО',
            type: 'success',
            timer: 5000,
          });
        })
        .catch((message) => {
          this.$notify({
            header: message,
            type: 'error',
            timer: 5000,
          });
        })
        .finally(() => {
          this.isLoadingSendingToAmo = false;
        });
    },

    onSendEmail() {
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      });

      this.isLoadingSendingEmail = true;
      const { contactMail, scriptId } = this.form;
      const payload = {
        email: contactMail,
        scriptId,
      };
      const isScriptTextChanged = this.currentScriptText && this.currentScriptText !== this.scriptText;
      
      if (isScriptTextChanged) {
        payload.message = this.currentScriptText;
      }

      this.sendEmail(payload)
        .then((result) => {
          if (result.isSucceed) {
            this.$notify({
              header: 'Коммерческое предложение отправлено',
              text: `Коммерческое предложение отправлено на почтовый адрес ${contactMail}`,
              type: 'success',
              timer: 5000,
            });
          }
        })
        .finally(() => {
          this.isLoadingSendingEmail = false;
        });
    },

    onOpenSmsModal() {
      this.showSmsModalForm = true;
    },

    onSendSms() {
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      });

      this.isLoadingSendingSms = true;
      this.sendSms({ ...this.smsForm, phone: this.form.organizationPhone })
        .then((result) => {
          if (result.isSucceed) {
            this.$notify({
              header: 'Смс отправлено',
              text: `Смс отправлено на номер `,
              type: 'success',
              timer: 5000,
            });
          }
        })
        .finally(() => {
          this.isLoadingSendingSms = false;
        });

      this.showSmsModalForm = false;
    },

    onCreateRelation() {
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      });

      const { id } = this.form;

      this.$router.push({
        name: 'TelemarketingTasksForCurrentUserReminderCreate',
        params: { relationForm: { relatedObjectId: id } },
      });
    },

    onCancelSendSms() {
      this.showSmsModalForm = false;
    },

    formatPhoneNumber(number) {
      return formatPhoneNumber(number);
    },

    // scrollToBottom() {
    //   window.scrollTo({
    //     top: document.body.scrollHeight,
    //     behavior: 'smooth',
    //   });
    // },

    webCallSession(number) {
      const firstNumberSymbol = number.charAt(0);
      let processedNumber = firstNumberSymbol === '7' ? `+${number}` : number;

      // this.$root
      //   .$confirmModal({
      //     message: `Набрать номер ${formatPhoneNumber(number)} по SIP?`,
      //     leftButtonText: 'Да',
      //     rightButtonText: 'Нет',
      //   })
      //   .then((response) => {
      //     if (response) {
      // this.scrollToBottom();
      const phoneNumberInput = document.getElementById(
        'zdrm-webphone-phonenumber-input'
      );
      if (this.hidePersonalData) {
        phoneNumberInput.style.display = 'none';
      }
      phoneNumberInput.value = '' + processedNumber;
      phoneNumberInput.dispatchEvent(new Event('change'));
      document
        .querySelector('.zdrm-webphone-call-btn')
        .dispatchEvent(new Event('click'));
    },
    // });
    // },

    onAddContact() {
      if (this.form.contacts === null) {
        this.form.contacts = [];
      }
      this.form.contacts.push(this.lodash.cloneDeep(this.initialContactForm));
    },

    openDeleteContactModal(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить контакт?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form.contacts.splice(index, 1);
          }
        });
    },

    openDeleteModal(tableKey, index) {
      this.$root
        .$confirmModal({
          message: 'Удалить запись?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form[tableKey].splice(index, 1);
          }
        });
    },

    createFormAnswersTable() {
      if (
        this.form.answers &&
        this.form.answers.length &&
        this.telemarketingScriptQuestions.length &&
        this.telemarketingAnswersList.length
      ) {
        return this.form.answers.map((item) => {
          let currentQuestion = this.telemarketingScriptQuestions.find(
            (question) => question.value === item.questionId
          );

          const currentAnswer = this.telemarketingAnswersList.find(
            (answer) => answer.id === item.answerId
          )?.name;

          return {
            question: currentQuestion?.name,
            answer: currentAnswer,
          };
        });
      }
      return [];
    },

    openModal(tableKey, index) {
      if (index !== null && index !== undefined) {
        this.editItemIndex = index;
        this.answersModalForm = { ...this.form[tableKey][index] };
        this.answersModalForm.chief = this.form.chief;
        
        const { questionId } = this.answersModalForm;
        const findedQuestion = this.telemarketingScriptQuestions.find(
          ({ value }) => value === questionId
        );

        if (findedQuestion) {
          this.currentTelemarketingScriptQuestions = [
            findedQuestion,
            ...this.notCreatedTelemarketingScriptQuestions,
          ];
        } else {
          this.currentTelemarketingScriptQuestions =
            this.notCreatedTelemarketingScriptQuestions;
        }
      } else {
        this.currentTelemarketingScriptQuestions =
          this.notCreatedTelemarketingScriptQuestions;

        let currentTable = this.fields.find(
          (item) => item.table && item.table.key === tableKey
        );
        for (let i in currentTable.table.modalFields) {
          if (currentTable.table.modalFields[i].type === 'checkbox') {
            this.answersModalForm = {
              ...this.answersModalForm,
              [currentTable.table.modalFields[i].key]: false,
            };
          } else {
            this.answersModalForm = {
              ...this.answersModalForm,
              [currentTable.table.modalFields[i].key]: '',
            };
          }
        }
      }
      this.showAnswersModalForm = true;
    },

    onSelectFromModal({ key }) {
      if (key === 'questionId') {
        this.answersModalForm.answerId = null;
      }
    },

    onSaveModalForm(tableKey) {
      this.showAnswersModalForm = false;
      for (let i in this.answersModalForm) {
        if (this.answersModalForm[i] === '') {
          delete this.answersModalForm[i];
        }
      }

      if (this.editItemIndex !== null) {
        const { questionId } = this.answersModalForm;

        let item = this.answersModalForm;

        if (questionId) {
          const findedQuestionName = this.telemarketingScriptQuestions.find(
            ({ value }) => value === questionId
          )?.name;

          item = { ...item, question: findedQuestionName };
        }

        this.form[tableKey].splice(this.editItemIndex, 1, {
          ...item,
        });
      } else {
        const { questionId } = this.answersModalForm;

        let item = this.answersModalForm;

        if (questionId) {
          const findedQuestionName = this.telemarketingScriptQuestions.find(
            ({ value }) => value === questionId
          )?.name;

          item = { ...item, question: findedQuestionName };
        }

        this.form[tableKey].push(item);
      }

      this.clearModalForm();
    },

    onCancelSaveModalForm() {
      this.clearModalForm();
    },

    clearModalForm() {
      this.editItemIndex = null;
      this.showAnswersModalForm = false;
      this.answersModalForm = {};
      this.modalTelemarketingAnswers = [];
      this.modalKey += 1;
    },

    onUpdate() {
      const formCopy = this.lodash.cloneDeep(this.form);
      this.$set(this.form, 'answers', this.getServerValidAnswers());

      const emptyFields = this.form.contacts.some((contact) => {
        // eslint-disable-next-line no-unused-vars
        const values = Object.entries(contact).filter(
          ([key, value]) => !!value && key !== 'id'
        );
        return values.length === 0;
      });

      if (emptyFields) {
        return this.$notify({
          header: 'Заполните данные добавленного контакта!',
          type: 'error',
          timer: 5000,
        });
      }

      this.isLoadingUpdateButton = true;

      const processedData = {
        ...this.form,
        contactPhoneMobile: this.contactPhoneMobileText,
        contactPhoneWork: this.contactPhoneWorkText,
        contacts: this.form.contacts.map((contact) => {
          // eslint-disable-next-line no-unused-vars
          const { isEdit, text, ...item } = contact;

          if (!text) return item;

          return {
            ...item,
            phone: text,
          };
        }),
      };

      this.updateItem({
        name: this.controllerName,
        payload: processedData,
      })
        .then(() => {
          this.getItem({ name: this.controllerName, id: this.id }).then(() => {
            const item = this.lodash.cloneDeep(this.item.data);

            const { contacts } = item;

            this.form = {
              ...item,
              answers: this.getCurrentAnswersTable(item?.answers),
              contacts: contacts.map((contact) => ({
                ...contact,
                isEdit: false,
                text: null,
              })),
            };

            this.$notify({
              header: 'Данные сохранены успешно',
              type: 'success',
              timer: 5000,
            });
          });
        })
        .catch(() => {
          this.form = formCopy;

          this.$notify({
            header: 'При сохранении данных произошла ошибка',
            type: 'error',
            timer: 5000,
          });
        })
        .finally(() => {
          this.isLoadingUpdateButton = false;
        });
    },

    onSave(route) {
      const emptyFields = this.form.contacts.some((contact) => {
        // eslint-disable-next-line no-unused-vars
        const values = Object.entries(contact).filter(
          ([key, value]) => !!value && key !== 'id'
        );
        return values.length === 0;
      });

      if (emptyFields) {
        return this.$notify({
          header: 'Заполните данные добавленного контакта!',
          type: 'error',
          timer: 5000,
        });
      }

      const query = {};

      if (this.previosPageParams.previosRouteName === route) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }

      this.isLoadingSaveButton = true;

      const processedData = {
        ...this.form,
        contacts: this.form.contacts.map((contact) => {
          // eslint-disable-next-line no-unused-vars
          const { isEdit, text, ...item } = contact;

          if (!text) return item;

          return {
            ...item,
            phone: text,
          };
        }),
      };

      this.updateItem({
        name: this.controllerName,
        payload: processedData,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.$router.push({ name: route, query });
          }
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    getCurrentAnswersTable(initAnswers = undefined) {
      const answers = initAnswers
        ? this.lodash.cloneDeep(initAnswers) || []
        : this.lodash.cloneDeep(this.item.data.answers) || [];
      const telemarketingScriptQuestions =
        this.telemarketingScriptQuestions.map(
          ({ name, value, multipleAnswers }) => ({
            question: name,
            questionId: value,
            comment: '',
            multipleAnswers,
          })
        ) || [];
      const currentAnswers = [...telemarketingScriptQuestions];

      for (let i = 0; i < answers.length; i++) {
        const currentAnswer = answers[i];
        const currentQuestionId = currentAnswer.questionId;
        const findedAnswerIndex = currentAnswers.findIndex(
          ({ questionId }) => questionId === currentQuestionId
        );

        if (findedAnswerIndex !== -1) {
          const findedAnswer = currentAnswers[findedAnswerIndex];
          let payload = {
            multipleAnswers: findedAnswer.multipleAnswers,
          };

          if (
            findedAnswer?.multipleAnswers &&
            Array.isArray(findedAnswer.answerId)
          ) {
            const answersArray = [findedAnswer.answer];
            payload = { ...findedAnswer };
            payload.id = [...findedAnswer.id, currentAnswer.id];

            if (currentAnswer.answerId) {
              payload.answerId.push(currentAnswer.answerId);
              answersArray.push(currentAnswer.answer);
            }

            payload.answer = answersArray.join('; ');
          } else if (findedAnswer?.multipleAnswers && currentAnswer?.answerId) {
            payload.answerId = [];
            payload.answer = '';
            payload.id = [currentAnswer.id];

            if (currentAnswer.answerId) {
              payload.answerId.push(currentAnswer.answerId);
              payload.answer = currentAnswer.answer;
            }
          }

          currentAnswers.splice(findedAnswerIndex, 1, {
            ...currentAnswer,
            ...payload,
          });
        }
      }

      if (this.form.answers?.length) {
        return currentAnswers.filter((answer) => 'id' in answer);
      }

      return currentAnswers;
    },

    getServerValidAnswers() {
      const answers = this.form.answers || [];
      const result = [];

      for (let i = 0; i < answers.length; i++) {
        const currentAnswer = answers[i];

        if (
          Array.isArray(currentAnswer?.answerId) &&
          currentAnswer.answerId.length
        ) {
          currentAnswer.answerId.forEach((answerId) => {
            const answerObject = { ...currentAnswer, answerId };

            delete answerObject.id;

            result.push(answerObject);
          });
        } else if (Array.isArray(currentAnswer?.answerId)) {
          const answerObject = { ...currentAnswer, answerId: null };

          delete answerObject.id;

          result.push(answerObject);
        } else if (Array.isArray(currentAnswer?.id)) {
          const answerObject = { ...currentAnswer };

          delete answerObject.id;

          result.push(answerObject);
        } else result.push(currentAnswer);
      }

      return result;
    },

    onSaveForm() {
      const formCopy = this.lodash.cloneDeep(this.form);

      this.$set(this.form, 'answers', this.getServerValidAnswers());
      this.onSave('TelemarketingTasksForCurrentUser');
      this.form = formCopy;
    },
  },
};
</script>

<style lang="scss">
.telemarketing-item__button {
  width: auto;
}

.editable-form__description--linked {
  align-self: center;
  width: 250px;
}

.editable-form__linked-text {
  margin-top: 6px;
  display: flex;
  align-items: center;
  width: 100%;
}

.editable-form__linked-text a {
  border-bottom: 1px dotted #000;
  text-decoration: none;
  margin-right: 5px;
}

// .telemarketing-item .editable-item__form-button-group {
//   align-items: center;
// }

.widget__webphone {
  // position: relative;
  // margin-left: auto;
  position: fixed;
  top: 24px;
  right: 80px;
}

// .telemarketing-item .zdrm-webphone-numpad-box {
//   position: absolute;
//   bottom: 45px;
// }

// .telemarketing-item .zdrm-webphone-media-box {
//   position: absolute;
//   bottom: 52px;
// }

// .telemarketing-item .zdrm-webrtc-scroller {
//   top: -4px;
// }

.telemarketing-form__row {
  display: flex;
  padding: 20px 0;
  border-top: 1px solid var(--light-gray);
  &:first-of-type {
    border: none;
  }
}

.telemarketing-form__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  columns: 2;
  column-gap: 30px;
}

.telemarketing-form__column {
  display: flex;
  flex: 0 0 50%;
  margin-bottom: 15px;
  &:nth-child(even) {
    flex: 0 0 47%;
  }
  &_textarea {
    flex-direction: column;
    flex: 1 1 100% !important;
    .textarea__label {
      color: var(--black);
    }
  }
}

.telemarketing-form__description {
  width: 250px;
  align-self: center;
}

.telemarketing-form__sms-wrapper {
  position: relative;
}

.telemarketing-form__sms-alert {
  position: absolute;
  bottom: -10px;

  font-size: 12px;
  color: var(--red);
}

.telemarketing-form__button {
  margin: 0;

  color: var(--gray);

  border: none;
  background: transparent;

  cursor: pointer;

  transition: color 0.3s;
}

.telemarketing-form__button:hover {
  color: var(--red);
}

.telemarketing-form__button--add {
  display: flex;
  align-items: center;

  line-height: 1;
  font-weight: 500;
  &:hover {
    color: var(--gray);
  }
}

.telemarketing-form__button--confirm {
  margin: 0 5px;
  &:hover {
    color: var(--green);
  }
}

.telemarketing-form__buttons {
  display: flex;
}

.telemarketing-item-modal {
  .editable-form__column {
    width: 100%;
  }
}

.editable-list__datatable-buttons {
  display: flex;
}

.telemarketing-item .modal {
  max-width: 90%;
}
</style>
